import React from 'react';
import "../templates/zoho-newsletter.css"
const ZohoNewsletter = () => {
  return (
    <div>
      <iframe
        title="Zoho Newsletter"
        
        width="100%"
        height="400"
        frameBorder="0"
        scrolling="auto"
        style={{background:"white",}}
        src="https://forms.zohopublic.com/digiryte/form/NewsLetter1/formperma/gNsj1a25NfJOgyF8Akz3DA7VGr4uE-fPrs302YPu_nU"
      ></iframe>
    </div>
  );
};

export default ZohoNewsletter;
